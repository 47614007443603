import React, { createContext, useContext, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode"; // Correct import for jwt-decode
import { useNavigate } from "react-router-dom";
import axios from "axios";

const StateContext = createContext();

export const StateProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [company, setCompany] = useState("Pandoza Solutions");
  const [deletecnf, setDeletecnf] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apptoken, setToken] = useState({
    access: "",
    refresh: "",
  });
  const logout = () => {
    localStorage.removeItem("hrms_access_token");
    localStorage.removeItem("hrms_refresh_token");
    setAuthenticated(false);
  };

  const [theme, setTheme] = useState({
    "--accent": "#0052cc",
    "--blue": "#05153a",
    "--black": "#000000",
    "--grey": "#d9d9d9",
    "--white": "#ffffff",
    "--light": "#6197e9",
  });

  const isTokenValid = async (token) => {
    if (!token) {
      logout();
      return false;
    }
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        logout();
        return false;
      }
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}verify-token/`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(token)}`,
            },
          }
        );

        if (response.status === 200 && response.data.valid) {
          return true;
        } else {
          logout();
          return false;
        }
      } catch (apiError) {
        logout();
        return false;
      }
    } catch (error) {
      logout();
      return false;
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("hrms_access_token");
    const refresh = localStorage.getItem("hrms_refresh_token");
    setToken({ access: token, refresh: refresh });
    if (token) {
      setAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    switch (company) {
      case "Pandoza Solutions":
        setTheme({
          "--accent": "#0052cc",
          "--blue": "#05153a",
          "--black": "#000000",
          "--grey": "#d9d9d9",
          "--white": "#ffffff",
          "--light": "#6197e9",
        });
        break;
      case "Akka Foundation":
        setTheme({
          "--accent": "#f18425",
          "--blue": "#000000",
          "--black": "#111111",
          "--grey": "#cccccc",
          "--white": "#f2f2f2",
          "--light": "#f07837bb",
        });
        break;
      default:
        setTheme({
          "--accent": "#0052cc",
          "--blue": "#05153a",
          "--black": "#000000",
          "--grey": "#d9d9d9",
          "--white": "#ffffff",
          "--light": "#6197e9",
        });
    }
  }, [company]);

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  useEffect(() => {
    for (const key in theme) {
      document.documentElement.style.setProperty(key, theme[key]);
    }
  }, [theme]);

  useEffect(() => {
    const interval = setInterval(() => {
      const token = localStorage.getItem("hrms_access_token");
      if (token) {
        isTokenValid(token);
      } else {
        logout();
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [apptoken.access]);

  // const refresh_token = localStorage.getItem("hrms_refresh_token");
  // const updateToken = async () => {
  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_URL}token/refresh/`,
  //     {
  //       refresh: JSON.parse(refresh_token)
  //     })
  //     console.log(response.data)
  //     localStorage.setItem("hrms_access_token",JSON.stringify(response.data.access))
  //   } catch (error) {
  //     console.log(error)
  //   }
  // };
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     updateToken();
  //   }, 100 * 60 * 1000); 
  //   return () => clearInterval(intervalId);
  // }, []);
  return (
    <StateContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        company,
        setCompany,
        formatDate,
        setLoading,
        loading,
        deletecnf,
        setDeletecnf,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateValue = () => useContext(StateContext);
