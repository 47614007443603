import React, { useEffect, useState } from "react";
import "./login.scss";
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../context/Context";
import { ClipLoader } from "react-spinners";

const Login = () => {
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveCredentials, setSaveCredentials] = useState(false);
  const [formdata, setFormdata] = useState({
    username: "",
    password: "",
  });
  const navigate = useNavigate();
  const { setAuthenticated } = useStateValue();


  // Load saved credentials if present
  useEffect(() => {
    const savedUsername = localStorage.getItem("saved_username");
    const savedPassword = localStorage.getItem("saved_password");
    if (savedUsername && savedPassword) {
      setFormdata({
        username: JSON.parse(savedUsername),
        password: JSON.parse(savedPassword),
      });
      setSaveCredentials(true);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    // Validate form fields
    if (!formdata.username || !formdata.password) {
      toast.error("Both fields are required.");
      return;
    }
    // Show loading toast
    try {
      setLoading(true)
      const response = await axios.post(
        'https://api.pandozasolutions.com/api/token/',
        formdata
      );
      
      if (response.data.access) {
        // console.log("zaigham",response.data.access);
        localStorage.setItem("hrms_access_token",JSON.stringify(response.data.access));
        localStorage.setItem("hrms_refresh_token",JSON.stringify(response.data.refresh));

        // Save credentials if checkbox is checked
        if (saveCredentials) {
          localStorage.setItem("saved_username", JSON.stringify(formdata.username));
          localStorage.setItem("saved_password", JSON.stringify(formdata.password));
        } else {
          // Remove saved credentials if checkbox is unchecked
          localStorage.removeItem("saved_username");
          localStorage.removeItem("saved_password");
        }

        setLoading(false);
        setAuthenticated(true);
        navigate("/"); // Redirect to the dashboard
      } else {
        toast.error("Invalid username or password.");
        setLoading(false);
      }
    } catch (error) {
      // console.log(error)
      toast.error("Invalid username or password.");
      setLoading(false);
      // Clear form data in case of error
      setFormdata({
        username: "",
        password: "",
      });
    }
  };
  
  return (
    <>
      <div className="login-parent parent">
        <form action="#" className="login-form" onSubmit={handleLogin}>
          <div className="heading">Hr Systems</div>
          <input
            type="text"
            name="username"
            placeholder="username"
            className="form-input"
            onChange={(e) =>
              setFormdata({ ...formdata, username: e.target.value })
            }
            value={formdata.username}
            required={true}
          />
          <div className="password-field">
            <input
              type={!toggle ? "password" : "text"}
              name="password"
              placeholder="password"
              className="form-input"
              value={formdata.password}
              onChange={(e) =>
                setFormdata({ ...formdata, password: e.target.value })
              }
              required={true}
            />
            <div className="icon" onClick={() => setToggle(!toggle)}>
              {toggle ? <IoMdEye /> : <IoMdEyeOff />}
            </div>
          </div>
          <div className="save-credentials">
            <input
              type="checkbox"
              id="save-credentials"
              checked={saveCredentials}
              onChange={() => setSaveCredentials(!saveCredentials)}
            />
            <label htmlFor="save-credentials">Save Credentials</label>
          </div>
          <button className="login-btn" type="submit">
            {loading ? <ClipLoader color="var(--white)" size={20} /> : "Login"}
          </button>
        </form>
      </div>
      <Toaster position="top-center" reverseOrder={true} />
    </>
  );
};

export default Login;
