import React, { useState } from "react";
import "./offerletter.scss";
import { useStateValue } from "../../context/Context";
import { Link } from "react-router-dom";
import OfferLetterPage from "../../components/OfferLetterPage";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const OfferLetter = () => {
  const { company, setCompany } = useStateValue();
  const [toggle, setToggle] = useState(true);
  const [formdata, setFormdata] = useState({
    employee_name: "",
    offer_date: "",
    joining_date: "",
    company_name: "",
    designation: "",
    department: "",
    monthly: "",
    professional_tax: "",
    tds: "",
    pf: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormdata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setFormdata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClear = () => {
    setFormdata({
      employee_name: "",
      offer_date: "",
      joining_date: "",
      company_name: "",
      designation: "",
      department: "",
      monthly: "",
    });
  };

  // console.log(formdata)

  const generatePDF = async (e) => {
    e.preventDefault();
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
      compress: true,
    });
    const pages = document.querySelectorAll(".offer-letter-container");
    for (let i = 0; i < pages.length; i++) {
      const canvas = await html2canvas(pages[i], { scale: 2 });
      const imgData = canvas.toDataURL("image/png");
      if (i > 0) pdf.addPage();
      pdf.addImage(imgData, "PNG", 0, 0, 210, 297);
    };
    pdf.save("offer-letter.pdf");
    handleClear();
  };
  return (
    <div className="offer-parent parent">
      {toggle ? (
        <div className="offer-container">
          <form action="#">
            <div className="form-row">
              <label>
                <p>Employee Name</p>
                <input
                  type="text"
                  name="employee_name"
                  placeholder="Employee Name"
                  onChange={handleChange}
                  value={formdata.employee_name}
                  required
                />
              </label>
            </div>
            <div className="form-row">
              <label>
                <p>Offer Date (DD-MM-YYYY)</p>
                <input
                  type="text"
                  name="offer_date"
                  placeholder="Offer Date (DD-MM-YYYY)"
                  onChange={handleDateChange}
                  value={formdata.offer_date}
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                  required
                />
              </label>
              <label>
                <p>Joining Date (DD-MM-YYYY)</p>
                <input
                  type="text"
                  name="joining_date"
                  placeholder="Joining Date (DD-MM-YYYY)"
                  onChange={handleDateChange}
                  value={formdata.joining_date}
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                  required
                />
              </label>
            </div>
            <div className="form-row">
              <label>
                <p>Company Name</p>
                <select
                  name="company_name"
                  className="select-tab"
                  value={formdata.company_name}
                  onChange={(e) => {
                    handleChange(e);
                    setCompany(e.target.value);
                  }}
                  required
                >
                  <option value="Pandoza Solutions" className="select-option">
                    Pandoza Solutions
                  </option>
                  <option value="Akka Foundation" className="select-option">
                    Akka Foundation
                  </option>
                </select>
              </label>
            </div>
            <div className="form-row">
              <label>
                <p>Designation</p>
                <input
                  type="text"
                  name="designation"
                  placeholder="Designation"
                  onChange={handleChange}
                  value={formdata.designation}
                  required
                />
              </label>
            </div>
            <div className="form-row">
              <label>
                <p>Department</p>
                <input
                  type="text"
                  name="department"
                  placeholder="Department"
                  onChange={handleChange}
                  value={formdata.department}
                  required
                />
              </label>
            </div>
            <div className="form-row">
              <label>
                <p>Monthly Salary</p>
                <input
                  type="text"
                  name="monthly"
                  placeholder="Monthly Salary"
                  onChange={handleChange}
                  value={formdata.monthly}
                  required
                />
              </label>
            </div>
            <div className="form-row">
              <label>
                <p>Professional Tax</p>
                <input
                  type="text"
                  name="professional_tax"
                  placeholder="Professional Tax"
                  value={formdata.professional_tax}
                  onChange={(e) =>
                    setFormdata({
                      ...formdata,
                      professional_tax: e.target.value,
                    })
                  }
                />
              </label>
              <label>
                <p>TDS</p>
                <input
                  type="text"
                  name="tds"
                  placeholder="TDS"
                  value={formdata.tds}
                  onChange={(e) =>
                    setFormdata({ ...formdata, tds: e.target.value })
                  }
                />
              </label>
            </div>
            <div className="form-row">
              <label>
                <p>Provident Fund (PF)</p>
                <input
                  type="text"
                  name="pf"
                  placeholder="Provident Fund (PF)"
                  value={formdata.pf}
                  onChange={(e) =>
                    setFormdata({ ...formdata, pf: e.target.value })
                  }
                />
              </label>
            </div>
            <div className="form-row-btn">
              <button
                className="submit-btn"
                type="button"
                onClick={() => setToggle(false)}
              >
                Preview
              </button>
              <button className="form-link" type="button" onClick={handleClear}>
                Clear
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="center-fix">
          <div
            id="offer-letter"
            style={{ padding: "20px", width: "210mm", height: "297mm" }}
          >
            <OfferLetterPage data={formdata} />
          </div>

          <div className="center-button-box">
            <button className="button-1" onClick={generatePDF}>
              Generate
            </button>
            <button className="button-2" onClick={()=>setToggle(true)}>
              Back
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OfferLetter;
