import React, { useEffect, useState, Suspense } from "react";
import "./employee.scss";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Button } from "@mui/material";
import { GridFooterContainer } from "@mui/x-data-grid";
import { useStateValue } from "../../context/Context";
import { Link } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-hot-toast";
import Confirmation from "../../components/Confirmation";
import male from "../../assets/Male.png";
import female from "../../assets/Female.png";
import { ClipLoader } from "react-spinners";
const LazyImage = React.lazy(() => import("../../components/LazyImage"));
const Employee = () => {
  const [data, setData] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const token = localStorage.getItem("hrms_access_token");
  const { setLoading } = useStateValue();

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_URL}employees/`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        }
      );
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteHandler = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_URL}employees/${id}/`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        }
      );
      fetchData();
      toast.success("Data removed successfully");
    } catch (error) {
      console.log(error);
      toast.error("Failed to remove data");
    }
    setShowConfirm(false);
    setDeleteId(null);
  };

  const confirmDelete = (params) => {
    setDeletinguser(params.row.employee_name);
    setDeleteId(params.row.id);
    setShowConfirm(true);
  };
  const [deletinguser, setDeletinguser] = useState("");
  const columns = [
    {
      field: "employee_id",
      headerName: "Employee Code",
      width: 120,
      renderCell: (params) => (
        <Link to={`/profile/${params.row.id}`} className="path">
          {params.value}
        </Link>
      ),
    },
    {
      field: "employee_image",
      headerName: "Profile Image",
      width: 120,
      renderCell: (params) => (
        <Link to={`/profile/${params.row.id}`}> 
        <Suspense
          fallback={
            <div className="loading-placeholder">
              <ClipLoader color="var(--accent)" size={30} />{" "}
            </div>
          }
        >
          <LazyImage
            src={
              params.value ||
              (params.row.gender === "Male" || params.row.gender === "male"
                ? male
                : female)
            }
            alt="Profile"
            className="profile-image"
            loading="lazy"
          />
        </Suspense>
        </Link>
        
      ),
    },
    {
      field: "employee_name",
      headerName: "Employee Name",
      width: 150,
      renderCell: (params) => (
        <Link to={`/profile/${params.row.id}`} className="path">
          {params.value}
        </Link>
      ),
    },
    {
      field: "attendance_code",
      headerName: "Attendance Code",
      width: 150,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 100,
    },
    {
      field: "employee_status",
      headerName: "Status",
      width: 150,
    },
    {
      field: "employee_position",
      headerName: "Position",
      width: 150,
    },
    {
      field: "date_of_joining",
      headerName: "Joining Date",
      width: 150,
    },
    {
      field: "date_of_leaving",
      headerName: "Leaving Date",
      width: 150,
    },
    {
      field: "contact_number",
      headerName: "Contact Number",
      width: 150,
    },
    {
      field: "email_id",
      headerName: "Email",
      width: 200,
    },
    {
      field: "date_of_birth",
      headerName: "Date of Birth",
      width: 150,
    },
    {
      field: "company_name",
      headerName: "Company Name",
      width: 150,
    },
    {
      field: "aadhar_number",
      headerName: "Aadhar Number",
      width: 150,
    },
    {
      field: "pan_number",
      headerName: "PAN Number",
      width: 150,
    },
    {
      field: "ifsc_code",
      headerName: "IFSC Code",
      width: 150,
    },
    {
      field: "bank_name",
      headerName: "Bank Name",
      width: 200,
    },
    {
      field: "account_number",
      headerName: "Account Number",
      width: 200,
    },
    {
      field: "employee_salary",
      headerName: "Monthly Salary",
      width: 150,
    },
    {
      field: "cost_to_company",
      headerName: "Cost to Company",
      width: 150,
    },
    // {
    //   field: "monthly_deductions",
    //   headerName: "Monthly Deductions",
    //   width: 150,
    // },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <div className="action-btns">
          <Link
            className="action-btn"
            style={{ marginLeft: 8 }}
            to={`/UpdateEmployee/${params.row.id}`}
          >
            <FaEdit />
          </Link>
          <button className="action-btn" onClick={() => confirmDelete(params)}>
            <FaTrash />
          </button>
        </div>
      ),
    },
  ];

  // Map the data to rows format
  const rows = data.map((item) => ({
    id: item.id,
    employee_id: item.employee_id,
    employee_name: item.employee_name,
    attendance_code: item.attendance_code,
    gender: item.gender,
    employee_status: item.employee_status,
    employee_position: item.employee_position,
    date_of_joining: item.date_of_joining,
    date_of_leaving: item.date_of_leaving,
    contact_number: item.contact_number,
    email_id: item.email_id,
    date_of_birth: item.date_of_birth,
    ifsc_code: item.ifsc_code,
    aadhar_number: item.aadhar_number,
    pan_number: item.pan_number,
    cost_to_company: item.cost_to_company,
    employee_salary: item.employee_salary,
    account_number: item.account_number,
    company_name: item.company_name,
    bank_name: item.bank_name,
    // monthly_deductions: item.monthly_deductions,
    employee_image: item.employee_image,
  }));

  return (
    <div className="employee-parent parent">
      <div className="employee-container">
        <div className="header-employee">
          <div className="headerTitle">Employee Data</div>
          <Link to="/CreateEmployee" className="btn">
            Create
          </Link>
        </div>
        <DataGrid
          className="datagrid"
          rows={rows}
          columns={columns}
          pagination
          pageSize={10}
          rowsPerPageOptions={[25, 50, 100]}
          disableMultipleRowSelection={true}
          disableRowSelectionOnClick={true}
        />
        {showConfirm && (
          <Confirmation
            user={deletinguser}
            onConfirm={() => deleteHandler(deleteId)}
            onCancel={() => setShowConfirm(false)}
          />
        )}
      </div>
    </div>
  );
};

export default Employee;
