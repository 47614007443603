import React from "react";
import logo from "../logo.png";
const RelivingLetter = () => {
  const Gap = (props) => (
    <div className="gap" style={{ height: `${props.height}px` }}></div>
  );

  const PageHeader = () => (
    <div className="pageheader">
      <div className="logo"></div>
    </div>
  );

  return (
    <>
      <div className="reliving-letter-parent offer-letter-parent">
        <div
          className="reliving-letter-container offer-letter-container"
          style={{ padding: "20px 60px", width: "210mm", height: "297mm" }}
        >
          <Gap height={10} />
          <img src={logo} style={{ width: "100px" }} alt="" />
          {/* <PageHeader /> */}

          {/* <Gap height={20} /> */}
          {/* <p>
            Office No : 214-216, 10 Biz Park, New Airport Rd, Next to Symbiosis
            Law College, Viman Nagar, Pune - 411006.
          </p> */}
          <Gap height={40} />
          <p>
            <span className="bold">Date</span>: <span contentEditable className="editable">02/06/2023</span>
          </p>
          <Gap height={20} />

          <p>To,</p>
          <p className="bold editable" contentEditable >Employee Name</p>
          <p className="editable" contentEditable >Employee adress</p>
          <p className="editable" contentEditable >Employee Pincode</p>
          <Gap height={20} />
          <p className="bold">Subject: <span contentEditable className="editable">Experience Letter for Employee Name</span></p>
          <Gap height={20} />
          <p className=" editable" contentEditable >Dear Employee Name</p>
          <Gap height={10} />
          <p contentEditable className="editable">
            I am writing this letter to provide my highest recommendation for
            Employee Name, who served as a Senior Content Writer at Pandoza
            Solutions Pvt ltd from February 1, 2022, to January 31, 2023.
          </p>
          <Gap height={20} />
          <p contentEditable className="editable">
            During her tenure, Employee Name consistently exhibited exceptional skills,
            dedication, and professionalism in her role. Her exceptional command
            over the English language, combined with her creative flair and
            attention to detail, made her an invaluable asset to our team. She
            consistently delivered high-quality written content that exceeded
            our expectations.
          </p>
          <Gap height={20} />
          <p contentEditable className="editable">
            Employee Name's ability to understand complex concepts and translate them
            into engaging and informative content was commendable. Her writing
            style was versatile, allowing her to adapt her tone and voice
            according to the target audience and the subject matter. She was
            adept at conducting in-depth research, which enabled her to produce
            well-researched and factually accurate content
          </p>
          <Gap height={20} />
          <p contentEditable className="editable">
            Moreover, Employee Name demonstrated excellent time management and
            organizational skills, ensuring that she consistently met project
            deadlines without compromising the quality of her work. She was
            proactive in seeking feedback and implementing suggested
            improvements, displaying a growth mindset and a commitment to
            personal and professional development
          </p>
          <Gap height={20} />
          <p contentEditable className="editable">
            Furthermore, Employee Name proved to be an excellent team player,
            collaborating effectively with colleagues from various departments.
            Her ability to work under pressure and maintain a positive attitude
            even in challenging situations was truly impressive. She
            consistently displayed strong communication skills, both written and
            verbal, which facilitated seamless coordination and ensured
            successful project outcomes.
          </p>
          <Gap height={70} />
          {/* <p contentEditable className="editable">
            In summary, Employee Name's contributions to our organization were
            invaluable, and she consistently exceeded our expectations in terms
            of quality, professionalism, and dedication. I have no doubt that
            she will excel in any future endeavor and bring significant value to
            any organization she joins.
          </p> */}
          <p contentEditable>Sincerely</p>
          <p contentEditable>Gaurav Ukinkar</p>
          <p contentEditable>HR Manager</p>
          <p contentEditable>Pandoza Solutions Pvt ltd</p>
          {/* <Gap height={20} /> */}
        </div>



        {/* <div
          className="reliving-letter-container offer-letter-container"
          style={{ padding: "20px 60px", width: "210mm", height: "297mm" }}
        >
          <Gap height={30} />
          <img src={logo} style={{ width: "200px" }} alt=""/>
          <Gap height={20} />
          <p contentEditable className="editable">
            I highly recommend Employee Name for any position that requires
            exceptional writing skills, creativity, and a strong work ethic.
            Should you require any further information or clarification, please
            do not hesitate to contact me.
          </p>
          <Gap height={20} />
          <p contentEditable className="editable">
            We Thank you for your contribution to the company and wish you all
            success in yourfuture endeavors.
          </p>
          <Gap height={20} />
          <p contentEditable className="editable">With Warm Regards,</p>
          <p className="bold" contentEditable>Pandoza Solutions Pvt Ltd</p>
          <Gap height={20} />
          <img src={logo} style={{ width: "200px" }} alt="" />
          <Gap height={20} />
          <Gap height={20} />
          <p className="bold" contentEditable>Authorized Signatory</p>
          <Gap height={20} />

          <p contentEditable>Sincerely</p>
          <p contentEditable>Gaurav Ukinkar</p>
          <p contentEditable>HR Manager</p>
          <p contentEditable>Pandoza Solutions Pvt ltd</p>
        </div> */}
      </div>
    </>
  );
};

export default RelivingLetter;
