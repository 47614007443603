import React from 'react';
import "./confirmation.scss";

const Confirmation = ({ onConfirm, onCancel,user }) => {
  return (
    <div className="confirmation-parent">
      <div className="confirmation-box">
        <div className="heading">
          Are you sure to delete {user}'s data?
        </div>
        <div className="button-box">
          <button className='alert-btn' onClick={onConfirm}>Yes</button>
          <button className='alert-btn' onClick={onCancel}>No</button>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
