import React from "react";
import { tailChase } from "ldrs";
import "./loaders.scss"
tailChase.register();
const Loaders = () => {
  return (
    <div className="loader-parent">
      <l-tail-chase size="40" speed="1.75" color="var(--accent)"></l-tail-chase>
    </div>
  );
};

export default Loaders;
