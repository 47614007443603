import React, { useState } from "react";
import "./offerletter.scss";
import { useStateValue } from "../context/Context";
import logo from "../assets/pan logo.png";
import akka from "../assets/akka.png";
const OfferLetterPage = (props) => {
  const { company } = useStateValue();
  const salary = {
    Gross: props.data.monthly,
    Gross_y: props.data.monthly * 12,
    Basic: (props.data.monthly * 40) / 100,
    basic_y: (props.data.monthly * 480) / 100,
    HRA: (props.data.monthly * 16) / 100,
    HRA_y: (props.data.monthly * 192) / 100,
    Travel: (props.data.monthly * 5) / 100,
    Travel_y: (props.data.monthly * 60) / 100,
    Medical: (props.data.monthly * 4) / 100,
    Medical_y: (props.data.monthly * 48) / 100,
    Special: (props.data.monthly * 35) / 100,
    Special_y: (props.data.monthly * 420) / 100,
  };
  // console.log(salary);
  const { formatDate } = useStateValue();

  const PageHeader = () => (
    <div className="pageheader">
      <div
        className="logo"
        style={{
          background: `url(${company == "Pandoza Solutions" ? logo : akka})`,
        }}
      ></div>
    </div>
  );

  const Gap = (props) => (
    <div className="gap" style={{ height: `${props.height}px` }}></div>
  );

  function formatIndianCurrency(number) {
    let numStr = number ? number.toString() : "00";
    let [whole, decimal] = numStr.split(".");
    let lastThree = whole.slice(-3);
    let otherNumbers = whole.slice(0, -3);
    if (otherNumbers !== "") {
      lastThree = "," + lastThree;
    }
    let formattedWhole =
      otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return decimal ? `${formattedWhole}.${decimal}` : formattedWhole;
  }
  console.log("props", props.data.pf);

  // total deductions
  const total_deductions =
    parseInt(props.data.professional_tax || 0) +
    parseInt(props.data.tds || 0) +
    parseInt(props.data.pf || 0);

  const total_deductions_y = total_deductions * 12;
  return (
    <div className="offer-letter-parent">
      {/* page 1 */}
      <div
        className="offer-letter-container"
        style={{ padding: "20px 60px", width: "210mm", height: "297mm" }}
      >
        <PageHeader />
        <Gap height={80} />
        <p className="underline center bold">Offer Letter</p>
        <Gap height={10} />
        <p>Date: {formatDate(props.data.offer_date)}</p>
        <Gap height={10} />

        <p>
          Dear <span className="bold"> {props.data.employee_name}</span>
        </p>
        <Gap height={30} />
        <p className="justify" contentEditable>
          Further to your interview, we are pleased to offer you the position of{" "}
          <span className="bold">{props.data.designation}</span> in our
          organization. Please refer to the attached Annexure-1 for your salary
          structure and an explanation of its components.
        </p>

        <Gap height={30} />
        <p className="justify" contentEditable>
          On joining, you will be subject to the employee policies and practices
          of{" "}
          <span className="bold">
            {company == "Pandoza Solutions"
              ? "Pandoza Solutions Private Limited"
              : "Akka Foundation"}
          </span>
          . A summary of the present policies is included in Annexure-2 to this
          offer letter for your reference. Also, refer to Annexure-3 for the
          list of documents to be submitted at the time of your joining,
        </p>
        <Gap height={30} />
        <p className="justify" contentEditable>
          You are required to join duties with effect from{" "}
          <span className="bold">{formatDate(props.data.joining_date)}</span> at
          our Pune office for this offer to be valid. You will be on probation
          period for a period of 3 months.
        </p>
        <Gap height={30} />
        <p contentEditable>
          Kindly report at the following address, at 10:00 a.m. on your date of
          joining –
        </p>
        <Gap height={30} />
        <p className="bold justify">
          {company == "Pandoza Solutions"
            ? "Pandoza Solutions Private Limited"
            : "Akka Foundation"}
        </p>
        <p className="left"  contentEditable>214, 10 Biz Park, Viman Nagar,</p>
        <p className="left" contentEditable>Pune, Maharastra, 411014.</p>
        <Gap height={30} />
        <p className="justify" contentEditable>
          {company == "Pandoza Solutions"
            ? "Pandoza Solutions Private Limited"
            : "Akka Foundation"}{" "}
          holds the right to cancel this offer with or without a reason at any
          time before you join.{" "}
          {company == "Pandoza Solutions"
            ? "Pandoza Solutions Private Limited"
            : "Akka Foundation"}{" "}
          may defer and/or cancel this offer at any time before or after your
          joining in case any information furnished by you is found incorrect or
          misleading.
        </p>
        <Gap height={30} />
        <p className="justify" contentEditable>
          We look forward to your joining{" "}
          {company == "Pandoza Solutions"
            ? "Pandoza Solutions Private Limited"
            : "Akka Foundation"}{" "}
          at the earliest and wish you a successful career with us.
        </p>

        <Gap height={30} />
        <p className="left">Thanking you,</p>
        <Gap height={30} />
        <p className="left">Sincerely,</p>
        <Gap height={30} />
        <p className="left">
          For{" "}
          {company == "Pandoza Solutions"
            ? "Pandoza Solutions Private Limited"
            : "Akka Foundation"}
          .
        </p>
        <Gap height={30} />
        <Gap height={30} />
        <Gap height={30} />
        <Gap height={30} />
        <p className="left" contentEditable>HR Manager</p>
        <p contentEditable>Gaurav Ukinkar</p>
        <Gap height={30} />
      </div>

      {/* page 2 */}
      <div
        className="offer-letter-container"
        style={{ padding: "20px 60px", width: "210mm", height: "297mm" }}
      >
         <PageHeader />
        <Gap height={60} />
       

        <p className="underline bold center">ANNEXURE-1</p>
        <p className="underline bold center">SALARY BREAKUP</p>
        <p className="left">
          Name: <span>{props.data.employee_name}</span>
        </p>
        <p>
          Designation: <span>{props.data.designation}</span>
        </p>
        <p className="left">
          Date of Joining: <span>{formatDate(props.data.joining_date)}</span>
        </p>
        <p className="left">
          Department: <span>{props.data.department}</span>
        </p>
        <p className="left">Location: Pune</p>
        <Gap height={10} />
        <table className="table-container">
          <tr className="table-heading">
            <th>No.</th>
            <th className="comp-salary">Component of Salary</th>
            <th>Amount Rs. (Monthly)</th>
            <th>Amount Rs. (Annually)</th>
          </tr>
          <tr className="table-data">
            <td className="bold">A</td>
            <td className="bold underline">Monthly Salary components</td>
            <td></td>
            <td></td>
          </tr>
          <tr className="table-data">
            <td></td>
            <td>Basic</td>
            <td>{formatIndianCurrency(salary.Basic)}</td>
            <td>{formatIndianCurrency(salary.basic_y)}</td>
          </tr>
          <tr className="table-data">
            <td></td>
            <td>HRA</td>
            <td>{formatIndianCurrency(salary.HRA)}</td>
            <td>{formatIndianCurrency(salary.HRA_y)}</td>
          </tr>
          <tr className="table-data">
            <td></td>
            <td>Travel </td>
            <td>{formatIndianCurrency(salary.Travel)}</td>
            <td>{formatIndianCurrency(salary.Travel_y)}</td>
          </tr>
          <tr className="table-data">
            <td></td>
            <td>Medical</td>
            <td>{formatIndianCurrency(salary.Medical)}</td>
            <td>{formatIndianCurrency(salary.Medical_y)}</td>
          </tr>
          <tr className="table-data">
            <td></td>
            <td>Special Allowance </td>
            <td>{formatIndianCurrency(salary.Special)}</td>
            <td>{formatIndianCurrency(salary.Special_y)}</td>
          </tr>
          <tr className="gap"></tr>
          <tr className="table-data">
            <td></td>
            <td className="bold">ANNUAL FIXED GROSS SALARY(A)</td>
            <td className="bold">{formatIndianCurrency(salary.Gross)}</td>
            <td className="bold">{formatIndianCurrency(salary.Gross_y)}</td>
          </tr>
          <tr className="table-data">
            <td className="bold">B</td>
            <td className="bold underline">Deduction</td>
            <td></td>
            <td></td>
          </tr>
          <tr className="table-data">
            <td></td>
            <td>Professional Tax</td>
            <td>{props.data.professional_tax || 0}</td>
            <td>{parseInt(props.data.professional_tax) * 12 || 0}</td>
          </tr>
          <tr className="table-data">
            <td></td>
            <td>TDS</td>
            <td>{props.data.tds || 0}</td>
            <td>{parseInt(props.data.tds) * 12 || 0}</td>
          </tr>
          <tr className="table-data">
            <td></td>
            <td>Provident Fund (PF)</td>
            <td>{props.data.pf || 0}</td>
            <td>{parseInt(props.data.pf) * 12 || 0}</td>
          </tr>
          <tr className="gap"></tr>
          <tr className="table-data">
            <td></td>
            <td className="bold">TOTAL DEDUCTION (B)</td>
            <td className="bold">{total_deductions || 0}</td>
            <td className="bold">{total_deductions_y || 0}</td>
          </tr>
          <tr className="gap"></tr>
          <tr className="table-data">
            <td className="bold">C</td>
            <td className="bold">COST TO COMPANY(A-B)</td>
            <td className="bold">
              {total_deductions
                ? formatIndianCurrency(
                    parseInt(salary.Gross) - parseInt(total_deductions)
                  )
                : formatIndianCurrency(parseInt(salary.Gross))}
            </td>
            <td className="bold">
              {total_deductions_y
                ? formatIndianCurrency(
                    parseInt(salary.Gross_y) - parseInt(total_deductions_y)
                  )
                : formatIndianCurrency(parseInt(salary.Gross_y))}
            </td>
          </tr>
        </table>
        <Gap height={10} />
        <p className="left" contentEditable>
          *Details are mentioned in the “Explanation of terms used” section.
        </p>
        <Gap height={10} />
        <p className="bold underline" contentEditable>Explanation of terms used:</p>
        <Gap height={10} />

        <p className="justify" contentEditable>
          <span className="bold">I.</span>
          <span className="bold"> Basic :</span>
          This is the base component of the salary to which many other
          components are linked. The amount is fully taxable.
        </p>
        <Gap height={10} />

        <p className="justify" contentEditable>
          <span className="bold">II.</span>
          <span className="bold"> HRA :</span>
          This amount will not be taxable if you submit the appropriate rent
          agreement and rent receipts. Tax benefit calculation will be done on
          the basis of provisions of the Income Tax Act, of 1961.
        </p>
        <Gap height={10} />

        <p className="justify" contentEditable>
          <span className="bold">III.</span>
          <span className="bold"> Special Allowance :</span>
          This will vary as it is based on the difference between your gross
          salary and other components that make up the entire salary. It is a
          fully taxable component.
        </p>
        <Gap height={10} />
        <p className="justify" contentEditable>
          <span className="bold">Income Tax:</span>
          Income tax and Professional tax will be deducted at source as per the
          rules applicable. The information pertaining to compensation and
          benefits is personal and confidential in nature. You should maintain
          the confidentiality of your compensation details and any increments.
        </p>
        <Gap height={30} />
      </div>
      {/* page 3 */}
      <div
        className="offer-letter-container"
        style={{ padding: "20px 60px", width: "210mm", height: "297mm" }}
      >
        <PageHeader />
        <Gap height={70} />
        
        <p className="underline bold center">ANNEXURE-2</p>
        <p className="underline bold center">
          Additional Terms and Conditions of Offer
        </p>
        <Gap height={10} />
        <p className="bold left">1. Date of joining:</p>
        <p className="justify" contentEditable>
          This offer for employment is subject to your joining and reporting to
          the designated{" "}
          {company == "Pandoza Solutions"
            ? "Pandoza Solutions Private Limited"
            : "Akka Foundation"}{" "}
          location on <span className="bold">{props.data.JoiningDate}</span>{" "}
          failing which this offer will stand withdrawn. In case of such
          withdrawal of the offer,{" "}
          {company == "Pandoza Solutions"
            ? "Pandoza Solutions Private Limited"
            : "Akka Foundation"}{" "}
          reserves the right to re-consider or reject your employment with
          Pandoza Solutions Private Limited.
        </p>
        <Gap height={10} />
        <p className="bold justify" contentEditable>2. Work location and transfer:</p>
        <p className="justify" contentEditable>
          Your initial location after joining will be at Pandoza Solutions
          Private Limited Pune office. This offer is subject to your
          preparedness to work in any of the locations of Pandoza Solutions
          Private Limited or its affiliates. Your services are transferable and
          you may be assigned to any office of{" "}
          {company == "Pandoza Solutions"
            ? "Pandoza Solutions Private Limited"
            : "Akka Foundation"}
          or an associate company on such project as Pandoza Solutions Private
          Limited may deem suitable.
        </p>
        <Gap height={10} />
        <p className="bold left" contentEditable>3. Mandatory tenure of employment: </p>
        <p className="justify" contentEditable>
          On joining{" "}
          {company == "Pandoza Solutions"
            ? "Pandoza Solutions Private Limited"
            : "Akka Foundation"}
          , you will continue to be employed with{" "}
          {company == "Pandoza Solutions"
            ? "Pandoza Solutions Private Limited"
            : "Akka Foundation"}{" "}
          for a minimum period of one year. If you resign or are terminated by
          Pandoza Solutions Private Limited for cause prior to completion of the
          said one year, you will be liable to pay{" "}
          {company == "Pandoza Solutions"
            ? "Pandoza Solutions Private Limited"
            : "Akka Foundation"}{" "}
          damages equivalent to three times your monthly gross salary. If failed
          to do so, Pandoza Solution will file legal action against the
          employee. Further, if you resign or are terminated by Pandoza
          Solutions Private Limited for cause prior to completion of the said
          one year, any special expenses incurred by{" "}
          {company == "Pandoza Solutions"
            ? "Pandoza Solutions Private Limited"
            : "Akka Foundation"}{" "}
          on your joining such as joining bonus, hiring allowance, notice
          buy-out, etc. will be recovered from you, in addition to the damages
          mentioned above.
        </p>
        <Gap height={10} />
        <p className="bold left">4. Background verification:</p>
        <p className="justify" contentEditable>
          This offer for employment is subject to the satisfactory completion of
          your background reference check, which includes verification of your
          past employment details based on the documents and information
          furnished by you at the time of joining Pandoza Solutions Private
          Limited and verification of all other documents submitted by you as a
          reference for your educational qualifications or any other
          credentials. In case you are unable to furnish the necessary documents
          and information for completing your background reference check or in
          case you furnish any misleading information or false documents,
          {company == "Pandoza Solutions"
            ? "Pandoza Solutions Private Limited"
            : "Akka Foundation"}{" "}
          reserves the right to terminate your employment irrespective of
          anything to the contrary in the Company’s Policies
        </p>
        <Gap height={10} />
        <p className="bold left">5. Travel and passport:</p>
        <p className="justify" contentEditable>
          You should possess a valid passport during your employment with
          {company == "Pandoza Solutions"
            ? "Pandoza Solutions Private Limited"
            : "Akka Foundation"}
          . In case you do not have a valid passport at the time of joining, you
          should get one issued within three months from the date of joining.
        </p>

        <Gap height={30} />
      </div>
      {/* page 4 */}
      <div
        className="offer-letter-container"
        style={{ padding: "20px 60px", width: "210mm", height: "297mm" }}
      >
        <PageHeader />
        <Gap height={70} />
     
        <p className="bold left">6. Confidentiality and return of materials:</p>
        <p className="justify" contentEditable>
          You will be required to maintain organizational secrecy and
          confidentiality with respect to information and procedures
          followed in{" "}
          {company == "Pandoza Solutions"
            ? "Pandoza Solutions Private Limited"
            : "Akka Foundation"}
          . You should not disclose any information/materials that are the
          intellectual property of
          {company == "Pandoza Solutions"
            ? "Pandoza Solutions Private Limited"
            : "Akka Foundation"}
          , its associate companies, or clients. Upon resignation or termination
          of your employment, you will return to{" "}
          {company == "Pandoza Solutions"
            ? "Pandoza Solutions Private Limited"
            : "Akka Foundation"}{" "}
          all papers and documents which may at that time be in your possession.
          This includes all types of material related to the business of Pandoza
          Solutions Private Limited or any of its associates or branches and you
          will not retain any copies or extracts therefrom.
        </p>
        <Gap height={10} />
        <p className="bold left">7. Non-competition:</p>
        <p className="justify" contentEditable>
          During the term of your employment with Pandoza Solutions Private
          Limited, you will not engage in any other employment, occupation,
          consulting, or other business activity related to the business in
          which{" "}
          {company == "Pandoza Solutions"
            ? "Pandoza Solutions Private Limited"
            : "Akka Foundation"}{" "}
          is now involved or becomes involved during the term of your
          employment. You will not engage in any other activity that conflicts
          with your obligations to Pandoza Solutions Private Limited during the
          term of your employment and for one year thereafter without the prior
          written consent of Pandoza Solutions Private Limited.
        </p>
        <Gap height={10} />
        <p className="bold left">8. Leaves and holidays:</p>
        <p className="justify" contentEditable>
          The company will announce holidays at the beginning of each year.
          Please note that there is no paid leave policy. To request leave, you
          must apply at least four days before it is considered
        </p>
        <Gap height={10} />
        <p className="bold left">9. Appraisal:</p>
        <p className="justify" contentEditable>
          Your performance shall be assessed at regular time intervals and you
          shall be eligible for salary revision based on your performance and
          your capability as well as the company’s performance. Your designation
          may change at the discretion of the Company.
        </p>
        <Gap height={10} />
        <p className="bold left">10. Dress code:</p>
        <p className="justify" contentEditable>
          The work dress code ranges from Formal to Business Casual to Casual.
          {company == "Pandoza Solutions"
            ? "Pandoza Solutions Private Limited"
            : "Akka Foundation"}
          ’s objective in establishing a dress code is to allow our employees to
          work comfortably in the workplace while projecting a professional
          image not only to our customers, potential employees, community, and
          visitors but also within the organization. Since all casual clothing
          is not suitable for the office, the dress code is specified in the
          employee manual to help you determine what is appropriate to wear to
          work. You should adhere to the dress code published in the employee
          manual on the intranet.
        </p>
        <Gap height={10} />
        <p className="left" contentEditable>
          You are required to wear formal on your date of joining, which is:
        </p>
        <Gap height={10} />
        <p className="justify " contentEditable>
          <span className="bold">*</span>
          <span className="bold">For Gentlemen:</span>
          Formal full-sleeve shirts and trousers with a tie and polished formal
          shoes.
        </p>
        <Gap height={10} />
        <p className="left" contentEditable>
          <span className="bold">*</span>
          <span className="bold">For Ladies:</span>
          Western formals, salwar-kameez or formal saris with sandals.
        </p>
        <Gap height={10} />
      </div>
      {/* page 5 */}
      <div
        className="offer-letter-container"
        style={{ padding: "20px 60px", width: "210mm", height: "297mm" }}
      >
        <PageHeader />
        <Gap height={70} />
        
        <p className="bold">11. Termination and resignation:</p>
        <p style={{ paddingLeft: "20px" }} className="justify bug-fix" contentEditable>
          A. <span className="bold"> Termination: </span>
          {company == "Pandoza Solutions"
            ? "Pandoza Solutions Private Limited"
            : "Akka Foundation"}{" "}
          reserves the right to terminate the &nbsp; &nbsp; &nbsp; services of an employee:
        </p>
        <p className="justify" style={{ paddingLeft: "40px" }} contentEditable>
          a. With or without cause by providing immediate termination.
        </p>
        <p className="justify" style={{ paddingLeft: "40px" }} contentEditable>
          b. Without notice in the following cases:
        </p>
        <p className="justify" style={{ paddingLeft: "60px" }} contentEditable>
          I. If the employee is absent or on unauthorized leave without notice
          in writing or without sufficient reasons for 5 days or more.
        </p>
        <p className="justify" style={{ paddingLeft: "60px" }} contentEditable>
          II. If the employee goes on a strike or supports a strike in
          contravention of any law for the time being in force 
          <br/>
          or
        </p>
        <p className="justify" style={{ paddingLeft: "60px" }} contentEditable>
          III. The employee causes damage to the physical or intellectual
          property of{" "}
          {company == "Pandoza Solutions"
            ? "Pandoza Solutions Private Limited"
            : "Akka Foundation"}{" "}
          or any of its clients/associates
        </p>
        <Gap height={10} />
        <p style={{ paddingLeft: "20px" }} className="justify" contentEditable>
          A. <span className="bold"> Resignation:</span>
          For resigning from{" "}
          {company == "Pandoza Solutions"
            ? "Pandoza Solutions Private Limited"
            : "Akka Foundation"}
          , you are required to serve a 1 months’ notice period as per the
          policy of resignation after the completion of 1 year with the
          employment and as applicable at the time of departure. In case of a
          shortfall in the notice period, the relieving date shall be the
          prerogative of the company and shall be within the notice period.
          Further, the company reserves the right to recover an amount
          equivalent to the consolidated salary for the number of days of the
          shortfall
        </p>
        <Gap height={10} />
        <p className="bold">12. Rules and regulations:</p>
        <p className="justify" contentEditable>
          You will be subject to all rules and regulations of Pandoza Solutions
          Private Limited that are in force and shall abide by them until in
          employment with the organization.{" "}
          {company == "Pandoza Solutions"
            ? "Pandoza Solutions Private Limited"
            : "Akka Foundation"}
          policies are updated from time to time. You are expected to be aware
          of the company’s policies and abide by them.{" "}
          {company == "Pandoza Solutions"
            ? "Pandoza Solutions Private Limited"
            : "Akka Foundation"}{" "}
          reserves the right to modify any or all of the above terms and
          conditions that shall be binding on you, from time to time. You will
          be governed by the code of conduct, discipline, rules, and regulations
          as laid down by the Company. These can be modified and updated from
          time to time, and these will be deemed to form an integral part of
          this offer of employment.
        </p>
        <Gap height={10} />
        <p className="bold">13. Acceptance:</p>
        <p className="justify" contentEditable>
          If the terms and conditions of this offer are acceptable to you,
          kindly return a duplicate of this letter of offer duly signed with
          your acceptance. Originals and photocopies of the following documents
          need to be submitted on the day of joining. Non- submission of any of
          the documents will lead to deferment of joining formalities. Original
          documents will be returned after verification.
        </p>
      </div>
      <div
        className="offer-letter-container"
        style={{ padding: "20px 60px", width: "210mm", height: "297mm" }}
      >
         <PageHeader />
        <Gap height={70} />
       
        {/* <p className="left">
          Originals and photocopies of the following documents need to be
          submitted on the day of joining. Non- submission of any of the
          documents will lead to deferment of joining formalities. Original
          documents will be returned after verification.
        </p> */}

        <p style={{ paddingLeft: "20px" }}>
          <ul>
            <li className="bold">Educational documents:</li>
          </ul>
          <ul style={{ paddingLeft: "20px", listStyleType: "upper-greek" }} contentEditable>
            <li>10th and 12th/Diploma mark sheets</li>
            <li> Degree certificate and mark sheet (all semesters/years)</li>
            <li>
              {" "}
              PG certificate and mark sheet (if applicable – for all
              semesters/years)
            </li>
            <li>Any Certification mark sheet/certificate</li>
          </ul>
        </p>
        <Gap height={10} />
        <p style={{ paddingLeft: "20px" }}>
          <ul>
            <li className="bold">Employment documents:</li>
          </ul>
          <ul style={{ paddingLeft: "20px", listStyleType: "upper-greek" }} contentEditable>
            <li>Relieving and Experience letters from past employers</li>
            <li> Last 3 salary slips</li>
            <li>
              Salary proof of fixed and variable components
              (appointment/increment letter)
            </li>
            <li>Bank statement for last 3 months (if working on contract)</li>
          </ul>
        </p>
        <Gap height={10} />
        <p style={{ paddingLeft: "20px" }}>
          <ul>
            <li className="bold">Personal documents:</li>
          </ul>
          <ul style={{ paddingLeft: "20px", listStyleType: "upper-greek" }} contentEditable>
            <li>Marriage certificate (if applicable)</li>
            <li> 3 passport-size photographs</li>
            <li>Passport Copy (If Applicable)</li>
            <li>PAN (Permanent Account Number) Card</li>
            <li>Aadhar Card</li>
          </ul>
        </p>
        <Gap height={10} />

        <p className="last-edit-parent" contentEditable>
          
          Before the date of joining, kindly forward a copy of your resignation
          letter and the acceptance of the same from your HR to <a
          className="mail"
              href={
                company == "Pandoza Solutions"
                  ? "hr@pandozasolutions.com"
                  : "hr@akkafoundation.in"
              }
            >
              {company == "Pandoza Solutions"
                ? "hr@pandozasolutions.com"
                : "hr@akkafoundation.in"}
            </a>
          
          
           
         
          You can also mail us at <a
          className="mail"
              href={
                company == "Pandoza Solutions"
                  ? "info@pandozasolutions.com"
                  : "info@akkafoundation.in"
              }
            >
              {company == "Pandoza Solutions"
                ? "info@pandozasolutions.com"
                : "info@akkafoundation.in"}
            </a>
          
          if you have any queries.
        </p>
        <Gap height={30} />
        <div
          className="letterofacceptance"
          style={{ border: "10px double  rgba(0,0,0,0.8)", padding: "10px" }}
        >
          <p className="bold underline center">ACCEPTANCE OF OFFER</p>
          <Gap height={30} />

          <p className="justify" contentEditable>
            I have read the offer letter and the annexed policies. I hereby
            accept the offer on the aforesaid terms.
          </p>
          <p className="justify" contentEditable>
            I solemnly affirm that I am not under any medication on account of
            any medical condition, which may adversely affect the performance of
            my duties in the event of my appointment pursuant to me acceptance
            of this offer.
          </p>
          <Gap height={30} />
          <p className="justify" contentEditable>
            I shall join duties with effect from the date mentioned hereinabove.
            In case of delays in joining, I shall inform the concerned authority
            one week in advance in writing.
          </p>
          <Gap height={30} />

          <div
            className="sign"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="left">
              <p>
                Name: <span>{props.data.Name}</span>
              </p>
              <p>Date:</p>
            </div>
            <div className="right-sign">
              <p className="" style={{ paddingRight: "150px" }}>
                Signature:
              </p>
              <p className="" style={{ paddingRight: "180px" }}>
                Place:
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferLetterPage;
