import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./profile.scss";
import axios from "axios";

const Profile = () => {
  const { id } = useParams();
  const token = localStorage.getItem("hrms_access_token");
  const [data, setData] = useState([]);

  const fetchEmployee = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}employees/${id}`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        }
      );
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEmployee();
  }, []);

  return (
    <div className="profile-parent">
      <div className="profile-container">
        <div className="user-details">
          <div className="user-details-left">
            <img
              src={data.employee_image}
              alt="User"
            />
          </div>
          <div className="user-details-right">
            <div className="heading">
              <h1>General Information</h1>
              <Link to={`/UpdateEmployee/${data.id}`} className="btn">
                Edit
              </Link>
            </div>
            <div className="info">
              <div className="info-details">
                <span className="font-medium">Name :</span>
                <span>{data.employee_name}</span>
              </div>
              <div className="info-details">
                <span className="font-medium">Employee Id :</span>
                <span>{data.employee_id}</span>
              </div>
              <div className="info-details">
                <span className="font-medium">Attendance Code :</span>
                <span>{data.attendance_code}</span>
              </div>
              <div className="info-details">
                <span className="font-medium">Gender :</span>
                <span>{data.gender}</span>
              </div>
              <div className="info-details">
                <span className="font-medium">Date of Birth :</span>
                <span>{data.date_of_birth}</span>
              </div>
              <div className="info-details">
                <span className="font-medium">Email :</span>
                <span>{data.email_id}</span>
              </div>
              <div className="info-details">
                <span className="font-medium">Contact Number :</span>
                <span>{data.contact_number}</span>
              </div>
              <div className="info-details">
                <span className="font-medium">Address :</span>
                <span>{data.employee_address}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="bank-details">
          <div className="bank-details-container">
            <div className="heading">
              <h1>Bank Details</h1>
            </div>
            <div className="info">
              <div className="info-details">
                <span className="font-medium">Bank Name :</span>
                <span>{data.bank_name}</span>
              </div>
              <div className="info-details">
                <span className="font-medium">IFSC Code :</span>
                <span>{data.ifsc_code}</span>
              </div>
              <div className="info-details">
                <span className="font-medium">Account Number :</span>
                <span>{data.account_number}</span>
              </div>
              <div className="info-details">
                <span className="font-medium">PAN Number :</span>
                <span>{data.pan_number}</span>
              </div>
              <div className="info-details">
                <span className="font-medium">Aadhar Number :</span>
                <span>{data.aadhar_number}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="employment-details">
          <div className="employment-details-container">
            <div className="heading">
              <h1>Employment Information</h1>
            </div>
            <div className="info">
              <div className="info-details">
                <span className="font-medium">Company :</span>
                <span>{data.company_name}</span>
              </div>
              <div className="info-details">
                <span className="font-medium">Position :</span>
                <span>{data.employee_position}</span>
              </div>
              <div className="info-details">
                <span className="font-medium">Date of Joining :</span>
                <span>{data.date_of_joining}</span>
              </div>
              {data.date_of_leaving && (
                <div className="info-details">
                  <span className="font-medium">Date of Leaving :</span>
                  <span>{data.date_of_leaving}</span>
                </div>
              )}
              <div className="info-details">
                <span className="font-medium">Monthly Salary :</span>
                <span>{data.employee_salary}</span>
              </div>
              <div className="info-details">
                <span className="font-medium">Cost To Company (CTC) :</span>
                <span>{data.cost_to_company}</span>
              </div>
              <div className="info-details">
                <span className="font-medium">Work Location :</span>
                <span>Pune, Maharastra</span>
              </div>
              <div className="info-details">
                <span className="font-medium">Employee Status :</span>
                <span>{data.employee_status}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
