import React, { useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "./relievingletter.scss";
import RelivingLetter from "../../components/RelivingLetter";

const RelievingLetter = () => {

  const removeEditableClass = () => {
    const editableElements = document.querySelectorAll(".editable");
    editableElements.forEach((element) => {
      element.classList.remove("editable");
    });
  };


  const generatePDF = async (e) => {
    e.preventDefault();
    removeEditableClass();

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
      compress: true
    });

    const pages = document.querySelectorAll(".reliving-letter-container");
    for (let i = 0; i < pages.length; i++) {
      const canvas = await html2canvas(pages[i], { scale: 2 });
      const imgData = canvas.toDataURL("image/png");
      if (i > 0) pdf.addPage();
      pdf.addImage(imgData, "PNG", 0, 0, 210, 297);
    }

    pdf.save("relieving-letter.pdf");
    window.location.reload();
  };

  return (
    <div className="relieving-letter-parent parent">
      <div
        id="relieving-letter"
        style={{ padding: "20px", width: "210mm", height: "297mm" }}
      >
        <RelivingLetter />
      </div>
      <button className="relieving-btn btn" onClick={generatePDF}>
        Generate
      </button>
    </div>
  );
};

export default RelievingLetter;
