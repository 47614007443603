import React, { useState } from "react";
import "./navbar.scss";
import { Link, useNavigate } from "react-router-dom";
import { useStateValue } from "../context/Context";
import Hamburger from "hamburger-react";

const Navbar = () => {
  const { setAuthenticated, company } = useStateValue();
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("hrms_refresh_token");
    localStorage.removeItem("hrms_access_token");
    setAuthenticated(false);
    navigate("/login");
  };
  return (
    <div className="navbar-parent parent">
      <div className="navbar-container">
        <div className="left">
          <Link to="/" className="title">
            {company}
          </Link>
        </div>
        <div className="right">
          <Link
            className={`link-item ${
              window.location.pathname == "/" && "active"
            }`}
            to="/"
          >
            Salary Slips
          </Link>
          <Link
            className={`link-item ${
              window.location.pathname == "/offerletter" && "active"
            }`}
            to="/offerletter"
          >
            Offer Letter
          </Link>
          <Link
            className={`link-item ${
              window.location.pathname == "/relievingletter" && "active"
            }`}
            to="/relievingletter"
          >
            Relieving Letter
          </Link>
          <Link
            className={`link-item ${
              (window.location.pathname == "/employees" ||
                window.location.pathname == "/CreateEmployee") &&
              "active"
            }`}
            to="/employees"
          >
            Employees
          </Link>
          {/* <Link className={`link-item ${window.location.pathname == "/" && "active"}`}>TBD</Link> */}
          <button
            className="logout-btn link-item"
            onClick={() => handleLogout()}
          >
            Logout
          </button>
        </div>

        {/* mobile settings */}
        <div className="menu-parent">
          <Hamburger toggled={isOpen} toggle={setOpen} />
        </div>

        {/* mobile submenu */}
        <div className={`mobile-menu ${isOpen ? "open" : ""}`}>
          <Link
            className={`link-item ${
              window.location.pathname == "/" && "active"
            }`}
            to="/"
            onClick={() => setOpen(false)}
          >
            Salary Slips
          </Link>
          <Link
            className={`link-item ${
              window.location.pathname == "/offerletter" && "active"
            }`}
            to="/offerletter"
            onClick={() => setOpen(false)}
          >
            Offer Letter
          </Link>
          <Link
            className={`link-item ${
              (window.location.pathname == "/employees" ||
                window.location.pathname == "/CreateEmployee") &&
              "active"
            }`}
            to="/employees"
            onClick={() => setOpen(false)}
          >
            Employees
          </Link>
          {/* <Link className={`link-item ${window.location.pathname == "/" && "active"}`}>TBD</Link> */}
          <button
            className="logout-btn link-item"
            onClick={() => handleLogout()}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
