import React, { useState } from "react";
import "./salaryslip.scss";
import logo from "../assets/pan logo.png";
import akka from "../assets/akka.png";
import numberToWords from "number-to-words";
import { useStateValue } from "../context/Context";
const SalarySlipPage = ({ data }) => {
  const { company } = useStateValue();
  const {
    accnumber,
    bankname,
    dedec,
    id,
    month_year,
    monthlySalary,
    name,
    paydate,
    pf,
    presentdays,
    professional_tax,
    slip_genration_date,
    tds,
    pannumber,
  } = data;

  const array = month_year.split("-");
  const month_number = array[1];
  const year = array[0];
  console.log(getDaysInMonth(month_number, year));
  const monthdays = getDaysInMonth(month_number, year) || 1;
  function getDaysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }
  function getMonthName(monthNumber) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[monthNumber - 1];
  }

  // const monthdays = 1;
  const month = getMonthName(month_number);
  //  const year = 2024;
  const special =
    monthlySalary -
    (0.4 * monthlySalary +
      0.16 * monthlySalary +
      0.05 * monthlySalary +
      0.04 * monthlySalary);

  console.log("monthly_salary__", monthlySalary);
  console.log("month_days__", monthdays);
  console.log("present_days__", presentdays);
  const lopsalary = (monthlySalary / monthdays) * (monthdays - presentdays);
  console.log("lop salary", lopsalary);

  const totaldedec =
    parseInt(dedec) +
      parseInt(lopsalary) +
      parseInt(pf) +
      parseInt(professional_tax) +
      parseInt(tds) || 0;

  const finalsalary = monthlySalary - lopsalary - dedec;
  const words = numberToWords.toWords((monthlySalary - totaldedec));
  console.log("professional_tax", professional_tax);
  console.log("total deduction", totaldedec);
  var Indian = new Intl.NumberFormat("en-In", {
    style: "currency",
    currency: "INR",
  });

  const convertDateFormat = (date) => {
    const [year, month, day] = date.split("-");
    return `${day}-${month}-${year}`;
  };

  console.log("total decution", totaldedec);
  return (
    <div className="salary-slip-parent">
      {/* page 1 */}
      <div
        className="salary-slip-container"
        style={{ padding: "20px", width: "210mm", height: "297mm" }}
      >
        <div>
          <div className="main-contaniner">
            <div className="header">
              <div className="logo">
                <img
                  src={company == "Pandoza Solutions" ? logo : akka}
                  alt=""
                />
              </div>
              <div className="dates">
                <p>Payslip for the month</p>
                <p className="bold">
                  {month} {year}
                </p>
              </div>
            </div>

            <div className="section2">
              <div className="left">
                <p className="bold">EMPLOYEE SUMMARY</p>
                <table>
                  <tr>
                    <td className="grey">Employee Name </td>
                    <td>:</td>
                    <td className="bold">{name}</td>
                  </tr>
                  <tr>
                    <td className="grey">Employee ID </td>
                    <td>:</td>
                    <td className="bold">{id}</td>
                  </tr>
                  <tr>
                    <td className="grey">Pay Period </td>
                    <td>:</td>
                    <td className="bold">
                      {month} {year}
                    </td>
                  </tr>
                  <tr>
                    <td className="grey">Pay Date </td>
                    <td>:</td>
                    <td className="bold">
                      {convertDateFormat(slip_genration_date)}
                    </td>
                  </tr>
                  <tr>
                    <td className="grey">Bank Name </td>
                    <td>:</td>
                    <td className="bold">{bankname}</td>
                  </tr>
                  <tr>
                    <td className="grey">Account No. </td>
                    <td>:</td>
                    <td className="bold">{accnumber}</td>
                  </tr>
                  <tr>
                    <td className="grey">PAN No. </td>
                    <td>:</td>
                    <td className="bold">{pannumber}</td>
                  </tr>
                </table>
              </div>
              <div className="right">
                <div className="top">
                  <p className="final" style={{ color: "#ffffff" }}>
                    {Indian.format(monthlySalary - totaldedec)}
                  </p>
                  <p style={{ color: "#ffffff" }}>Employee Net Pay</p>
                </div>
                <div className="bottom">
                  <table>
                    <tr>
                      <td>Paid Days</td>
                      <td>:</td>
                      <td>{presentdays}</td>
                    </tr>
                    <tr>
                      <td>LOP Days </td>
                      <td>:</td>
                      <td>{monthdays - presentdays}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div className="section3">
              <table className="main-table table-left">
                <tr>
                  <th className="bold grey">EARNINGS</th>
                  <th className="bold grey">AMOUNT</th>
                </tr>
                <tr>
                  <td className="grey">Basic</td>
                  <td className="bold">{Indian.format(0.4 * monthlySalary)}</td>
                </tr>
                <tr>
                  <td className="grey">HRA</td>
                  <td className="bold">
                    {Indian.format(0.16 * monthlySalary)}
                  </td>
                </tr>
                <tr>
                  <td className="grey">Travel</td>
                  <td className="bold">
                    {Indian.format(0.05 * monthlySalary)}
                  </td>
                </tr>
                <tr>
                  <td className="grey">Medical</td>
                  <td className="bold">
                    {Indian.format(0.04 * monthlySalary)}
                  </td>
                </tr>
                <tr>
                  <td className="grey">Special</td>
                  <td className="bold">{Indian.format(special)}</td>
                </tr>
                <tr>
                  <td className="grey"></td>
                  <td className="bold"></td>
                </tr>
                <tr>
                  <td className="grey"></td>
                  <td className="bold"></td>
                </tr>
                <tr>
                  <td className="grey"></td>
                  <td className="bold"></td>
                </tr>
                <tr>
                  <td className="grey"></td>
                  <td className="bold"></td>
                </tr>
                <tr>
                  <td className="grey"></td>
                  <td className="bold"></td>
                </tr>
                <tr>
                  <td className="grey"></td>
                  <td className="bold"></td>
                </tr>
                <tr>
                  <td className="grey"></td>
                  <td className="bold"></td>
                </tr>
                <tr>
                  <td className="grey"></td>
                  <td className="bold"></td>
                </tr>
                <div className="line"></div>
                <tr className="footer-heading">
                  <td className="bold" >Gross Earning</td>
                  <td className="bold">{Indian.format(monthlySalary)}</td>
                </tr>
              </table>
              <table className="main-table table-right">
                <tr>
                  <th className="bold grey">DEDUCTIONS</th>
                  <th className="bold grey">AMOUNT</th>
                </tr>
                <tr>
                  <td className="grey">Income Tax</td>
                  <td className="bold">₹0.00</td>
                </tr>
                <tr>
                  <td className="grey">Professional Tax</td>
                  <td className="bold">{Indian.format(professional_tax)}</td>
                </tr>
                <tr>
                  <td className="grey">LOP (Loss of pay)</td>
                  <td className="bold">{Indian.format(lopsalary)}</td>
                </tr>
                <tr>
                  <td className="grey">Provident Fund</td>
                  <td className="bold">{Indian.format(pf)}</td>
                </tr>
                <tr>
                  <td className="grey">TDS</td>
                  <td className="bold">{Indian.format(tds)}</td>
                </tr>
                <tr>
                  <td className="grey">Other Deduction / Loans EMIs </td>
                  <td className="bold">{Indian.format(dedec)}</td>
                </tr>
                <tr>
                  <td className="grey">I</td>
                  <td className="bold">I</td>
                </tr>

                <tr className="footer-heading">
                  <td className="bold">Total Deduction</td>
                  <td className="bold">{Indian.format(totaldedec)}</td>
                </tr>
              </table>
            </div>

            <div className="section4">
              <div className="left">
                <p className="bold">TOTAL NET PAYABLE</p>
                <p className="grey">Gross Earnings - Total Deductions</p>
              </div>
              <div className="right">
                <p className="bold" style={{ color: "#ffffff" }}>
                  {Indian.format(monthlySalary - totaldedec)}
                </p>
              </div>
            </div>
            <div className="section5">
              <p className="grey">Amount In Words : </p>
              <p className="bold words-conversion"> &nbsp;{words} only</p>
            </div>

            <div className="section6">
              <p className="grey bold">Note-</p>
              <p>
                This is a computer generated slip, does not require signature.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalarySlipPage;
