import React, { useEffect, useState } from "react";
import "./create.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useStateValue } from "../../context/Context";
import { Toaster, toast } from "react-hot-toast";
import axios from "axios";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
const CreateEmployee = () => {
  const { company, setCompany } = useStateValue();
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const { id } = useParams();
  const [formdata, setFormdata] = useState({
    employee_name: "",
    employee_id: "",
    attendance_code: "",
    gender: "0",
    employee_status: "0",
    employee_position: "",
    date_of_joining: "",
    date_of_leaving: "",
    contact_number: "",
    email_id: "",
    date_of_birth: "",
    ifsc_code: "",
    aadhar_number: "",
    pan_number: "",
    cost_to_company: "",
    employee_salary: "",
    account_number: "",
    company_name: company,
    bank_name: "",
    monthly_deductions: "0",
    employee_image: null,
    employee_address: "",
  });

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFormdata({ ...formdata, [name]: value });
  };

  const fileChangeHandler = (e) => {
    const file = e.target.files[0]; // Get the first file
    const maxSize = 500 * 1024; // 500KB in bytes

    if (file) {
      if (file.size > maxSize) {
        // File is too large
        toast.error("File size should not exceed 500KB.");
        e.target.value = null; // Clear the file input
      } else {
        setFormdata({ ...formdata, employee_image: e.target.files[0] });
      }
    }
  };
  const token = localStorage.getItem("hrms_access_token");
  console.log(formdata);
  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.keys(formdata).forEach((key) => {
      formData.append(key, formdata[key]);
    });
    console.log("inner_data", formData);
    try {
      let response;
      if (pathname.startsWith("/UpdateEmployee/")) {
        response = await axios.put(
          `${process.env.REACT_APP_URL}employees/${id}/`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(token)}`,
            },
          }
        );
        toast.success("Employee Data Updated!");
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_URL}employees/`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(token)}`,
            },
          }
        );
        toast.success("Employee Created!");
      }

      clear();
    } catch (error) {
      if (
        error.response.data.employee_name &&
        error.response.data.employee_id
      ) {
        toast.error("Employee name and Id already exists.");
      } else if (error.response.data.employee_name) {
        toast.error("Employee name already exists.");
      } else if (error.response.data.employee_id) {
        toast.error("Employee Id already exists.");
      } else if (error.response.data.attendance_code) {
        toast.error("Attendence code already exists.");
      }
    }
  };

  const clear = () => {
    setFormdata({
      employee_name: "",
      employee_id: "",
      attendance_code: "",
      gender: "0",
      employee_status: "0",
      employee_position: "",
      date_of_joining: "",
      date_of_leaving: "",
      contact_number: "",
      email_id: "",
      date_of_birth: "",
      ifsc_code: "",
      aadhar_number: "",
      pan_number: "",
      cost_to_company: "",
      employee_salary: "",
      account_number: "",
      company_name: company,
      bank_name: "",
      monthly_deductions: "",
      employee_image: null,
      employee_address: "",
    });
  };

  console.log(token);
  const existingEmployee = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}employees/${id}/`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        }
      );
      console.log(response.data);
      setFormdata({
        employee_name: response.data.employee_name,
        employee_id: response.data.employee_id,
        attendance_code: response.data.attendance_code,
        gender: response.data.gender,
        employee_status: response.data.employee_status,
        employee_position: response.data.employee_position,
        date_of_joining: response.data.date_of_joining,
        date_of_leaving: response.data.date_of_leaving || "",
        contact_number: response.data.contact_number,
        email_id: response.data.email_id,
        date_of_birth: response.data.date_of_birth,
        ifsc_code: response.data.ifsc_code,
        aadhar_number: response.data.aadhar_number,
        pan_number: response.data.pan_number,
        cost_to_company: response.data.cost_to_company,
        employee_salary: response.data.employee_salary,
        account_number: response.data.account_number,
        company_name: response.data.company_name,
        bank_name: response.data.bank_name,
        monthly_deductions: response.data.monthly_deductions,
        employee_address: response.data.employee_address,
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (pathname.startsWith("/UpdateEmployee/")) {
      existingEmployee();
    }
  }, []);

  console.log("image", formdata.employee_image);
  return (
    <div className="create-employee-parent parent">
      <form action="#" onSubmit={submitHandler}>
        <div className="form-row">
          <span className="form-heading">
            {pathname.startsWith("/UpdateEmployee/")
              ? "Update Employee"
              : "Create Employee"}
          </span>
        </div>
        <div className="form-row">
          <label>
            <p>Employee Name</p>
            <input
              type="text"
              placeholder="Employee Name"
              name="employee_name"
              value={formdata.employee_name}
              onChange={(e) => changeHandler(e)}
              required={true}
            />
          </label>
        </div>
        <div className="form-row">
          <label>
            <p>Employee Id</p>
            <input
              type="text"
              placeholder="Employee Id"
              name="employee_id"
              value={formdata.employee_id}
              onChange={(e) => changeHandler(e)}
              required={true}
              readOnly={pathname.startsWith("/UpdateEmployee/")}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Employee Id"
            />
            <Tooltip id="my-tooltip" />
          </label>
          <label>
            <p>Attendance Code</p>
            <input
              type="text"
              placeholder="Attendance Code"
              name="attendance_code"
              value={formdata.attendance_code}
              onChange={(e) => changeHandler(e)}
              required={true}
              readOnly={pathname.startsWith("/UpdateEmployee/")}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Attendance Code"
            />
            <Tooltip id="my-tooltip" />
          </label>
        </div>
        <div className="form-row">
          <label>
            <p>Gender</p>
            <select
              name="gender"
              value={formdata.gender}
              onChange={(e) => changeHandler(e)}
              required={true}
            >
              <option value="0" disabled>
                Select Gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Others">Others</option>
            </select>
          </label>
          <label>
            <p>Employee Status</p>
            <select
              name="employee_status"
              value={formdata.employee_status}
              onChange={(e) => changeHandler(e)}
              required={true}
            >
              <option value="0" disabled>
                Employee Status
              </option>
              <option value="On Board">On Board</option>
              <option value="On Leave">On Leave</option>
              <option value="Terminated">Terminated</option>
              <option value="Resigned">Resigned</option>
              <option value="Retired">Retired</option>
              <option value="Probation">Probation</option>
              <option value="Contract">Contract</option>
              <option value="Intern">Intern</option>
              <option value="Part-Time">Part-Time</option>
              <option value="Full-Time">Full-Time</option>
            </select>
          </label>
        </div>
        <div className="form-row">
          <label>
            <p>Employee Position</p>
            <input
              type="text"
              placeholder="Employee Position"
              name="employee_position"
              value={formdata.employee_position}
              onChange={(e) => changeHandler(e)}
              required={true}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Employee Position"
            />
            <Tooltip id="my-tooltip" />
          </label>
          <label>
            <p>Date of Joining</p>
            <input
              type={formdata.date_of_joining ? "date" : "text"}
              placeholder="Date of Joining"
              name="date_of_joining"
              value={formdata.date_of_joining}
              onChange={(e) => changeHandler(e)}
              onFocus={(e) => (e.target.type = "date")}
              required={true}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Date Of Joining"
            />
            <Tooltip id="my-tooltip" />
          </label>
        </div>
        <div className="form-row">
          <label>
            <p>Date of Leaving</p>
            <input
              type={formdata.date_of_leaving ? "date" : "text"}
              placeholder="Date of Leaving"
              name="date_of_leaving"
              value={formdata.date_of_leaving}
              onChange={(e) => changeHandler(e)}
              onFocus={(e) => (e.target.type = "date")}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Date Of Leaving"
            />
            <Tooltip id="my-tooltip" />
          </label>
          <label>
            <p>Contact Number</p>
            <input
              type="text"
              placeholder="Contact Number"
              name="contact_number"
              value={formdata.contact_number}
              onChange={(e) => changeHandler(e)}
              required={true}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Contact Number"
            />
            <Tooltip id="my-tooltip" />
          </label>
        </div>
        <div className="form-row">
          <label>
            <p>Email Id</p>
            <input
              type="email"
              placeholder="Email Id"
              name="email_id"
              value={formdata.email_id}
              onChange={(e) => changeHandler(e)}
              required={true}
            />
          </label>
          <label>
            <p>Date of Birth</p>
            <input
              type={formdata.date_of_birth ? "date" : "text"}
              placeholder="Date of Birth"
              name="date_of_birth"
              value={formdata.date_of_birth}
              onChange={(e) => changeHandler(e)}
              required={true}
              onFocus={(e) => (e.target.type = "date")}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Date of Birth"
            />
            <Tooltip id="my-tooltip" />
          </label>
        </div>
        <div className="form-row-textarea form-row">
          <label>
            <p>Address</p>
            <textarea
              name="employee_address"
              placeholder="Address"
              value={formdata.employee_address}
              onChange={(e) => changeHandler(e)}
            />
          </label>
        </div>
        <div className="form-row">
          <label>
            <p>Bank Name</p>
            <input
              type="text"
              placeholder="Bank Name"
              name="bank_name"
              value={formdata.bank_name}
              onChange={(e) => changeHandler(e)}
              required={true}
            />
          </label>
          <label>
            <p>Account Number</p>
            <input
              type="text"
              placeholder="Account Number"
              name="account_number"
              value={formdata.account_number}
              onChange={(e) => changeHandler(e)}
              required={true}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Account Number"
            />
            <Tooltip id="my-tooltip" />
          </label>
        </div>
        <div className="form-row">
          <label>
            <p>IFSC Code</p>
            <input
              type="text"
              placeholder="IFSC Code"
              name="ifsc_code"
              value={formdata.ifsc_code}
              onChange={(e) => changeHandler(e)}
              required={true}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="IFSC Code"
            />
            <Tooltip id="my-tooltip" />
          </label>
          <label>
            <p>Monthly Salary</p>
            <input
              type="text"
              placeholder="Monthly Salary"
              name="employee_salary"
              value={formdata.employee_salary}
              onChange={(e) => {
                setFormdata({
                  ...formdata,
                  employee_salary: e.target.value,
                  cost_to_company: 12 * e.target.value,
                });
              }}
              required={true}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Monthly Salary"
            />
            <Tooltip id="my-tooltip" />
          </label>
        </div>
        <div className="form-row">
          <label>
            <p>PAN Number</p>
            <input
              type="text"
              placeholder="PAN Number"
              name="pan_number"
              value={formdata.pan_number}
              onChange={(e) => changeHandler(e)}
              required={true}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="PAN Number"
            />
            <Tooltip id="my-tooltip" />
          </label>
          <label>
            <p>Aadhar Number</p>
            <input
              type="text"
              placeholder="Aadhar Number"
              name="aadhar_number"
              value={formdata.aadhar_number}
              onChange={(e) => changeHandler(e)}
              required={true}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Aadhar Number"
            />
            <Tooltip id="my-tooltip" />
          </label>
          {/* <label>
      <p>Cost to Company</p>
      <input
        type="text"
        placeholder="Cost to Company"
        name="cost_to_company"
        value={formdata.cost_to_company}
        onChange={(e) => changeHandler(e)}
        required={true}
        data-tooltip-id="my-tooltip"
        data-tooltip-content="CTC"
      />
      <Tooltip id="my-tooltip" />
    </label> */}
        </div>

        <div className="form-row">
          <label>
            <p>Company Name</p>
            <select
              name="company_name"
              className="select-tab"
              value={company}
              required={true}
              onChange={(e) => {
                setCompany(e.target.value);
                changeHandler(e);
              }}
            >
              <option value="Pandoza Solutions" className="select-option">
                Pandoza Solutions
              </option>
              <option value="Akka Foundation" className="select-option">
                Akka Foundation
              </option>
            </select>
          </label>
          {!pathname.startsWith("/UpdateEmployee/") && (
            <label>
              <p>Employee Image</p>
              <input
                type="file"
                name="employee_image"
                onChange={(e) => fileChangeHandler(e)}
              />
            </label>
          )}
        </div>
        {/* <div className="form-row">
    <label>
      <p>Monthly Deductions</p>
      <input
        type="text"
        placeholder="Monthly Deductions"
        name="monthly_deductions"
        value={formdata.monthly_deductions}
        onChange={(e) => changeHandler(e)}
        required={true}
        data-tooltip-id="my-tooltip"
        data-tooltip-content="Monthly Deductions"
      />
      <Tooltip id="my-tooltip" />
    </label>
   
  </div> */}
        <div className="form-row-btn">
          <button
            className="form-link"
            onClick={() => navigate(-1)}
            type="button"
          >
            Back
          </button>
          <button className="submit-btn" type="submit">
            {pathname.startsWith("/UpdateEmployee/") ? "Update" : "Register"}
          </button>
          <button className="form-link" type="button" onClick={() => clear()}>
            Clear
          </button>
        </div>
      </form>

      <Toaster position="top-right" reverseOrder={true} />
    </div>
  );
};

export default CreateEmployee;
