// src/App.js
import React, { useState } from "react";
import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./authentication/Login";
import { useStateValue } from "./context/Context";
import Dashboard from "./pages/dashboard/Dashboard";
import Navbar from "./components/Navbar";
import Employee from "./pages/employee/Employee";
import OfferLetter from "./pages/offerletter/OfferLetter";
import SalarySlip from "./pages/salaryslip/SalarySlip";
import CreateEmployee from "./pages/createemployee/CreateEmployee";
import OfferLetterPage from "./components/OfferLetterPage";
import SalarySlipPage from "./components/SalarySlipPage";
import Loaders from "./components/Loaders";
import Confirmation from "./components/Confirmation";
import Profile from "./pages/profile/Profile";
import RelievingLetter from "./pages/relievingletter/RelievingLetter";
import RelivingLetter from "./components/RelivingLetter";
import Sidebar from "./components/sidebar/Sidebar";
import Layout from "./Layout";
const App = () => {
  const { authenticated, loading } = useStateValue();
  return (
    <>
      <BrowserRouter>
        {authenticated ? (
          <>
            <Navbar/>
          {/* <Sidebar/> */}
            {loading && <Loaders />}
            {/* <Layout> */}
              <Routes>
                {/* <Route path="/dashboard" element={<Dashboard />} /> */}
                <Route path="/employees" element={<Employee />} />
                <Route path="/offerletter" element={<OfferLetter />} />
                <Route path="/relievingletter" element={<RelievingLetter />} />
                <Route path="/" element={<SalarySlip />} />
                <Route path="/CreateEmployee" element={<CreateEmployee />} />
                <Route
                  path="/UpdateEmployee/:id"
                  element={<CreateEmployee />}
                />
                {/* <Route path="/of" element={<OfferLetterPage />} />
            <Route path="/sp" element={<SalarySlipPage />} /> */}
                <Route path="/profile/:id" element={<Profile />} />
                <Route path="/rl" element={<RelivingLetter />} />
              </Routes>
            {/* </Layout> */}
          </>
        ) : (
          <Routes>
            <Route path="/*" element={<Login />} />
          </Routes>
        )}
      </BrowserRouter>
    </>
  );
};

export default App;
