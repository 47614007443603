import React, { useState, useEffect } from "react";
import "./salaryslip.scss";
import { Link } from "react-router-dom";
import { useStateValue } from "../../context/Context";
import SalarySlipPage from "../../components/SalarySlipPage";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";
import toast from "react-hot-toast";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
const SalarySlip = () => {
  const { company, setCompany } = useStateValue();
  const mydate = new Date();
  const [toggle, setToggle] = useState(true);
  // Function to format date to yyyy-mm-dd
  function formatDateToYYYYMMDD(date) {
    if (typeof date === "string") {
      date = new Date(date);
    }
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  }
  // Function to format date to dd/mm/yyyy
  function formatDateToDDMMYYYY(date) {
    if (typeof date === "string") {
      date = new Date(date);
    }
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }
  const [employee, setEmployee] = useState([]);
  const [employeeId, setEmployeeId] = useState("0");
  const [filtered, setFiltered] = useState({});

  const fetchEmployee = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}employees/`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("hrms_access_token")
            )}`,
          },
        }
      );
      setEmployee(response.data);
    } catch (error) {
      toast.error("Network error.");
    }
  };

  useEffect(() => {
    fetchEmployee();
  }, []);

  useEffect(() => {
    if (employeeId === "0") {
      setFiltered({});
    } else {
      const selectedEmployee = employee.find(
        (emp) => emp.id === parseInt(employeeId)
      );
      setFiltered(selectedEmployee || {});
    }
  }, [employeeId, employee]);

  const [formdata, setFormdata] = useState({
    name: "",
    monthlySalary: "",
    paydate: "",
    bankname: "",
    accnumber: "",
    presentdays: "",
    monthdays: "",
    dedec: "0",
    id: "",
    slip_genration_date: "",
    tds: "0",
    pf: "0",
    month_year: "",
    professional_tax: "200",
    pannumber: "",
  });

  useEffect(() => {
    setFormdata((prevFormdata) => ({
      ...prevFormdata,
      name: filtered.employee_name || "",
      monthlySalary: filtered.employee_salary || "",
      bankname: filtered.bank_name || "",
      accnumber: filtered.account_number || "",
      id: filtered.employee_id || "",
      pannumber: filtered.pan_number || "",
    }));
  }, [filtered]);
  console.log("filtered_data__", filtered);
  const generatePDF = (e) => {
    e.preventDefault();
    const input = document.getElementById("salary-slip");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });
      pdf.addImage(imgData, "PNG", 0, 0, 210, 297);
      pdf.save("salary-slip.pdf");
    });
  };

  const clear = () => {
    setFormdata({
      name: "",
      monthlySalary: "",
      paydate: "",
      bankname: "",
      accnumber: "",
      presentdays: "",
      monthdays: "",
      dedec: "0",
      id: "",
      slip_genration_date: "",
      tds: "0",
      pf: "0",
      month_year: "",
      professional_tax: "200",
      pannumber: "",
    });
    setEmployeeId("0");
  };

  console.log("formdata__", formdata);
  return (
    <div className="salary-parent parent">
      {toggle ? (
        <div className="salary-container">
          <form action="#">

            {/* first row */}
            <div className="form-row">
              <label>
                <p>Select Employee</p>
                <select
                  name=""
                  id=""
                  className="select-tab"
                  value={employeeId}
                  onChange={(e) => setEmployeeId(e.target.value)}
                  required
                >
                  <option value="0" disabled>
                    Select Employee
                  </option>
                  {employee.map((data) => (
                    <option
                      key={data.id}
                      value={data.id}
                      className="select-option"
                    >
                      {data.employee_name}
                    </option>
                  ))}
                </select>
              </label>
            </div>

            {/* second row */}
            <div className="form-row">
              <label>
                <p>Employee Id</p>
                <input
                  type="text"
                  required
                  placeholder="Employee Id"
                  value={formdata.id}
                  readOnly
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Employee Id"
                />
                <Tooltip id="my-tooltip" />
              </label>
              <label>
                <p>Monthly Salary</p>
                <input
                  type="number"
                  placeholder="Monthly Salary"
                  value={formdata.monthlySalary}
                  readOnly
                  required
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Monthly Salary"
                />
                <Tooltip id="my-tooltip" />
              </label>
            </div>

            {/* third row */}
            <div className="form-row">
              <label>
                <p>Company</p>
                <select
                  name=""
                  id=""
                  className="select-tab"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  required
                >
                  <option value="Pandoza Solutions" className="select-option">
                    Pandoza Solutions
                  </option>
                  <option value="Akka Foundation" className="select-option">
                    Akka Foundation
                  </option>
                </select>
              </label>
            </div>

            {/* fourth row */}
            <div className="form-row">
              <label>
                <p>Account Number</p>
                <input
                  type="number"
                  placeholder="Account Number"
                  value={formdata.accnumber}
                  readOnly
                  required
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Account Number"
                />
                <Tooltip id="my-tooltip" />
              </label>
              <label>
                <p>Pan Number</p>
                <input
                  type="text"
                  placeholder="Pan Number"
                  value={formdata.pannumber}
                  readOnly
                  required
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Pan Number"
                />
                <Tooltip id="my-tooltip" />
              </label>
            </div>

            {/* fifth row */}
            <div className="form-row">
              <label>
                <p>Bank Name</p>
                <input
                  type="text"
                  placeholder="Bank Name"
                  value={formdata.bankname}
                  readOnly
                  onChange={(e) =>
                    setFormdata({ ...formdata, bankname: e.target.value })
                  }
                  required
                />
              </label>
              <label>
                <p>TDS</p>
                <input
                  type="text"
                  placeholder="TDS"
                  value={formdata.tds}
                  onChange={(e) =>
                    setFormdata({ ...formdata, tds: e.target.value })
                  }
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="TDS"
                />
                <Tooltip id="my-tooltip" />
              </label>
            </div>

            {/* sixth row */}
            <div className="form-row">
              <label>
                <p>Provident Fund</p>
                <input
                  type="text"
                  placeholder="Provident Fund"
                  value={formdata.pf}
                  onChange={(e) =>
                    setFormdata({ ...formdata, pf: e.target.value })
                  }
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Provident Fund"
                />
                <Tooltip id="my-tooltip" />
              </label>
              <label>
                <p>Professional Tax</p>
                <input
                  type="text"
                  placeholder="Professional Tax"
                  value={formdata.professional_tax}
                  onChange={(e) =>
                    setFormdata({
                      ...formdata,
                      professional_tax: e.target.value,
                    })
                  }
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Professional Tax"
                />
                <Tooltip id="my-tooltip" />
              </label>
            </div>

            {/* seventh row */}
            <div className="form-row">
              <label>
                <p>Monthly Deductions</p>
                <input
                  type="number"
                  placeholder="Monthly Deductions"
                  value={formdata.dedec}
                  onChange={(e) =>
                    setFormdata({ ...formdata, dedec: e.target.value })
                  }
                  required
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Monthly Deductions"
                />
                <Tooltip id="my-tooltip" />
              </label>
              <label>
                <p>Month, year</p>
                <input
                  type={formdata.month_year ? "month" : "text"}
                  placeholder="Month, year"
                  onFocus={(e) => (e.target.type = "month")}
                  value={formdata.month_year}
                  onChange={(e) => {
                    setFormdata({ ...formdata, month_year: e.target.value });
                  }}
                />
              </label>
            </div>

            {/* eighth row */}
            <div className="form-row">
              <label>
                <p>Present Day</p>
                <input
                  type="number"
                  placeholder="Present Day"
                  value={formdata.presentdays}
                  onChange={(e) =>
                    setFormdata({ ...formdata, presentdays: e.target.value })
                  }
                  required
                />
              </label>
              <label>
                <p>Slip Generation Date</p>
                <input
                  type={formdata.slip_genration_date ? "date" : "text"}
                  placeholder="Slip Generation Date"
                  value={formdata.slip_genration_date}
                  onFocus={(e) => (e.target.type = "date")}
                  onChange={(e) =>
                    setFormdata({
                      ...formdata,
                      slip_genration_date: e.target.value,
                    })
                  }
                />
              </label>
            </div>

            {/* button row */}
            <div className="form-row-btn">
              <button className="submit-btn" type="button" onClick={()=>setToggle(false)}>
                Preview
              </button>
              <button
                className="form-link"
                type="button"
                onClick={() => clear()}
              >
                Clear
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="center-fix">
          <div
            id="salary-slip"
            style={{ padding: "20px", width: "210mm", height: "297mm" }}
          >
            <SalarySlipPage data={formdata} />
          </div>

          <div className="center-button-box">
            <button className="button-1" onClick={generatePDF}>
              Generate
            </button>
            <button className="button-2" onClick={() => setToggle(true)}>
              Back
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SalarySlip;
